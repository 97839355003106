<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full">
        <div>
            <div class="flex justify-center">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
                <img class="h-12" src="../assets/img/logos/sjef_logo-white.png" alt="Sjef logo" />
            </div>
            <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white">
                Sign in to your KDS
            </h2>
        </div>
        <form class="mt-8" action="#" @submit.prevent="submit" autocomplete="off">
            <div class="rounded-md shadow-sm">
                <div>
                    <input placeholder="Bedrijfs CODE" v-model="form.client_code" autofocus name="client_code" type="text" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"  />
                </div>
                <div class="-mt-px">
                    <input placeholder="Terminal CODE" v-model="form.terminal_code" name="terminal_code" type="text" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 @error('terminal_code') border-red-500 @enderror" />
                </div>
                <div class="-mt-px">
                    <input placeholder="Pincode" v-model="form.terminal_pincode" name="terminal_pincode" type="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5 @error('terminal_pincode') border-red-500 @enderror" />
                </div>
            </div>


            <div class="mt-6">
                <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-orange-500 hover:bg-orange-400 focus:outline-none focus:border-orange-600 focus:shadow-outline-orange active:bg-orange-600 transition duration-150 ease-in-out">
                    <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                        <svg class="h-5 w-5 text-orange-200 group-hover:text-orange-100 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                        </svg>
                    </span>
                    Sign in
                </button>
            </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Login',
    data () {
        return {
            form: {
                client_code: '',
                terminal_code: '',
                terminal_pincode: '',
            }
        }
    },

    methods: {
        ...mapActions({
            login: 'auth/login'
        }),

        async submit () {
            await this.login(this.form)

            this.$router.replace({ name: 'orders' })
        }
    }
}
</script>
